import React, { useEffect } from 'react';
import * as R from 'ramda';
import { IoCreateOutline } from 'react-icons/io5';
import { useModalState } from '../../hooks/useModal';
import { FancyCheckbox } from '../Common/Controls';
import { TextField } from '../Common/Fields';
import { Modal } from '../Common/Modal';
import { constructNodeView } from '../../common/got-adjunct';
import { useGraph, ORGANIZATION_ID_KEY } from '../../common/hooks.config.new';
import { useUserEmail } from '../../hooks/useUserEmail';
import { SaveButton } from '../Elements/SaveButton';
import { useToast } from '../../hooks/useToast';
import { isValidMail } from '../../common/util';

export const EditContactModal = ({ contact, setContact, push, stack }) => {
    const [isOpen, close] = useModalState('new-contact');
    return (
        <Modal
            name="new-contact"
            style={{
                height: '47vh',
                width: '37vw',
                top: '27.5vh',
                left: '31vw',
            }}
            topbar={<IoCreateOutline size={25} color="var(--corporate-color-7)" />}
        >
            {isOpen ? (
                <EditContactModalContent
                    contact={contact}
                    push={push}
                    close={close}
                    setContact={setContact}
                    stack={stack}
                />
            ) : null}
        </Modal>
    );
};

const EditContactModalContent = ({ contact, setContact, push, close, stack }) => {
    const { useView, setRights, useVar, pull } = useGraph(...stack);
    const [organizationId] = useVar(ORGANIZATION_ID_KEY);
    const organizationView = constructNodeView(organizationId, { rights: true });
    const { [organizationId]: { rights } = {} } = useView(organizationView);

    const user = useUserEmail();
    const userRights = R.compose(
        R.sortBy(R.ifElse(R.propOr(false, 'isCurrentUser'), R.always('0000'), R.prop('user'))),
        R.filter(
            R.anyPass([
                R.propOr(false, 'read'),
                R.propOr(false, 'write'),
                R.propOr(false, 'admin'),
            ]),
        ),
        R.values,
        R.mapObjIndexed((val, key) => ({ ...val, user: key, isCurrentUser: key === user })),
        R.prop('user'),
    )(rights);

    const contactHasOrgRights = cntct => {
        const userRightEntry = R.filter(userEntry => userEntry.user === cntct)(userRights);
        const hasRights = R.length(userRightEntry) > 0;
        return hasRights;
    };

    useEffect(() => {
        organizationId && pull(organizationView);
    }, [organizationId]);
    const { [organizationId]: organizationBag = {} } = useView(organizationView);
    const canAdminOrganization = R.pathOr(
        false,
        ['rights', 'user', user, 'admin'],
        organizationBag,
    );

    const setToast = useToast('app');

    const checkAndSave = () => {
        if (!contact.name) {
            setToast('hint', 'Please enter a name', 5000);
            return;
        }
        if (!contact.email) {
            setToast('hint', 'Please enter an email', 5000);
            return;
        }
        if (!isValidMail(contact.email)) {
            setToast('hint', 'Please enter a valid email', 5000);
            return;
        }
        push();
        close();
    };

    return (
        <div className="columns">
            <div>
                <div className="modal-headline">
                    <div>Edit Contact</div>
                </div>
                <TextField
                    label="Name"
                    value={contact.name || ''}
                    onChange={value => setContact(R.assoc('name', value))}
                    mandatory
                />
                <TextField
                    label="E-Mail"
                    value={contact.email || ''}
                    onChange={value => setContact(R.assoc('email', value))}
                    mandatory
                />
                <TextField
                    label="Department"
                    value={contact.department || ''}
                    onChange={value => setContact(R.assoc('department', value))}
                />
                {user !== contact.email && canAdminOrganization && (
                    <FancyCheckbox
                        placeholderStyle={{ color: 'var(--corporate-color-2)' }}
                        label="Blocked. (This will remove all user's organization rights.
                He will see an empty dashboard after logging in the next time and
                no longer have access to any projects or subprojects)"
                        onChange={() => {
                            contact.blocked && contactHasOrgRights(contact.email)
                                ? setContact(R.assoc('blocked', true))
                                : setContact(R.assoc('blocked', !contact.blocked));
                            contactHasOrgRights(contact.email) &&
                                setRights(organizationId)(contact.email, {
                                    read: false,
                                    write: false,
                                    admin: false,
                                });
                        }}
                        value={contact.blocked && !contactHasOrgRights(contact.email)}
                    />
                )}
                <SaveButton save={checkAndSave} />
            </div>
        </div>
    );
};
